import { FC } from "react";

import { Stack, useTheme } from "@mui/material";
import { AvailablePaymentCurrency } from "@simplyk/common";
import InfiniteScroll from "react-infinite-scroll-component";

import { Typography } from "../../../../components/design-system/Typography";

import { InformationDialog, InformationDialogProps } from "@/design-system/Dialog/InformationDialog";
import { Divider } from "@/design-system/Divider";
import { Donor, DonorProps } from "@/features/FormV2/Step1/PeerToPeer/Donor";
import { DonorSkeleton } from "@/features/FormV2/Step1/PeerToPeer/DonorSkeleton";
import { trpc } from "@/helpers/trpc";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useTranslate } from "@/hooks/useTranslate";

const PAGE_SIZE = 20;

export type DonorsInformationDialogProps = Pick<StatelessDonorsInformationDialogProps, "open" | "onClose"> & {
  donationFormId: string;
};

export const DonorsInformationDialog: FC<DonorsInformationDialogProps> = ({ donationFormId, open, onClose }) => {
  const { isFetching, isFetchingNextPage, data, hasNextPage, fetchNextPage } = trpc.getLatestDonations.useInfiniteQuery(
    {
      formId: donationFormId,
      limit: PAGE_SIZE,
    },
    {
      enabled: open,
      initialCursor: 0,
      getNextPageParam: (lastPage) => lastPage?.nextCursor,
    }
  );

  const donors =
    data?.pages
      ?.filter((x) => !!x)
      ?.map((x) => x!.items)
      ?.flat()
      ?.map((x) => ({
        fullName: x.fullName,
        donatedOn: new Date(x.donatedOn),
        donationAmount: x.amount,
        donationCurrency: x.currency as AvailablePaymentCurrency,
      })) ?? [];

  return (
    <StatelessDonorsInformationDialog
      open={open}
      onClose={onClose}
      isLoading={isFetching && !isFetchingNextPage}
      donors={donors}
      hasMoreDonors={hasNextPage ?? false}
      onFetchMoreDonors={fetchNextPage}
    />
  );
};

type StatelessDonorsInformationDialogProps = Pick<InformationDialogProps, "open" | "onClose"> & {
  isLoading?: boolean;
  donors: DonorProps[];
  hasMoreDonors: boolean;
  onFetchMoreDonors: () => void;
};

export const StatelessDonorsInformationDialog: FC<StatelessDonorsInformationDialogProps> = ({
  isLoading = false,
  donors,
  open,
  onClose,
  hasMoreDonors,
  onFetchMoreDonors,
}) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const { isSmallScreen } = useMediaQuery();

  const renderInfiniteScrollLoader = () => {
    return (
      <Stack gap={2} marginBlockStart={2}>
        <Divider />
        <Stack gap={2} divider={<Divider />}>
          {Array.from({ length: 4 }).map((_, index) => (
            <DonorSkeleton key={index} />
          ))}
        </Stack>
      </Stack>
    );
  };

  const title = () => {
    return <Typography variant="h6">{t("donationForm", "peerToPeer.donorsDialog.title")}</Typography>;
  };

  return (
    <InformationDialog title={title()} open={open} onClose={onClose} noPadding>
      <InfiniteScroll
        dataLength={donors.length}
        next={onFetchMoreDonors}
        hasMore={hasMoreDonors}
        loader={renderInfiniteScrollLoader()}
        height={isSmallScreen ? "70dvh" : 460}
        style={{ padding: theme.spacing(3) }}
      >
        <Stack gap={2} divider={<Divider />}>
          {isLoading
            ? Array.from({ length: 7 }).map((_, index) => <DonorSkeleton key={index} />)
            : donors.map((donor, index) => <Donor key={index} {...donor} />)}
        </Stack>
      </InfiniteScroll>
    </InformationDialog>
  );
};
