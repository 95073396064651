import { forwardRef } from "react";

import MuiDialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";

import { InformationDialogLayout } from "./InformationDialogLayout";

import { IconButton } from "@/design-system/IconButton";
import { Typography } from "@/design-system/Typography";
import { Cross as Close } from "@/icons/outlined";

export type InformationDialogProps = Omit<MuiDialogProps, "classes" | "onClose" | "title" | "fullWidth"> & {
  title: React.ReactNode;
  children: React.ReactNode;
  onClose?: () => void;
  noPadding?: boolean;
  actions?: React.ReactNode;
  noDivider?: boolean;
};

export const InformationDialog = forwardRef<HTMLDivElement, InformationDialogProps>(
  (
    { open = false, title, children, onClose, noPadding = false, actions, noDivider = false, maxWidth, ...rest },
    ref
  ) => {
    return (
      <MuiDialog {...rest} fullWidth open={open} onClose={onClose} ref={ref} maxWidth={maxWidth}>
        <InformationDialogLayout
          slots={{
            title,
            closeButton: (
              <IconButton vibe="neutral" onClick={onClose}>
                <Close />
              </IconButton>
            ),
            content: (
              <MuiDialogContent sx={{ padding: noPadding ? 0 : undefined }}>
                <Typography variant="body2" component="div">
                  {children}
                </Typography>
              </MuiDialogContent>
            ),
            actions: actions && <MuiDialogActions>{actions}</MuiDialogActions>,
          }}
          noDivider={noDivider}
        />
      </MuiDialog>
    );
  }
);

InformationDialog.displayName = "InformationDialog";
