import { useTheme } from "@mui/material";

import { Divider } from "@/design-system/Divider";
import { GHOST_PREVIEW_OPACITY } from "@/features/LiveFormEditor/LivePreview/ghostPreview.constant";
import { useIsGhostPreview } from "@/features/LiveFormEditor/LivePreview/useIsGhostPreview";

export const DonationFormDivider = () => {
  const dividerIsGhostPreview = useIsGhostPreview("Amounts");
  const theme = useTheme();

  return (
    <Divider
      vibe={theme.palette.border.form.quiet}
      sx={{ width: "100%", ...(dividerIsGhostPreview && { opacity: GHOST_PREVIEW_OPACITY }) }}
    />
  );
};
