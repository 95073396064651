/* eslint-disable sonarjs/no-duplicate-string */
import { FC, useContext } from "react";

import { FormType } from "@simplyk/common";
import { parseAsStringLiteral, useQueryState } from "nuqs";

import { useFormV2Context } from "../context/FormV2Context";
import { HeartsAnimationProvider } from "../context/heartsAnimationContext";
import { donationFormHasTarget } from "../helpers/donationFormHasTarget";

import { DonationFormV2CheckoutFooter } from "./CheckoutFooter/DonationFormV2CheckoutFooter";
import { DonationFormV2Step1Layout } from "./DonationFormV2Step1Layout";
import { VersionControlAlertV2 } from "./VersionControlAlertV2";

import { NotificationBannerHeightContext } from "@/components/NotificationBanners/NotificationBannerHeightContext";
import { useUtmForm } from "@/features/FormV2/hooks/useUtmForm";
import { Amounts } from "@/features/FormV2/Step1/DonationForm/Amounts";
import { DonationFormDivider } from "@/features/FormV2/Step1/DonationForm/DonationFormDivider";
import { useDonationFirstStepSubmit } from "@/features/FormV2/Step1/DonationForm/useDonationFirststepSubmit";
import { FormV2Banner } from "@/features/FormV2/Step1/FormV2Banner";
import { FormV2LogoChip } from "@/features/FormV2/Step1/FormV2LogoChip";
import { FormV2Summary } from "@/features/FormV2/Step1/FormV2Summary";
import { Donor } from "@/features/FormV2/Step1/PeerToPeer/Donor";
import { DonorsInformationDialog } from "@/features/FormV2/Step1/PeerToPeer/DonorsInformationDialog";
import { DonorsTile } from "@/features/FormV2/Step1/PeerToPeer/DonorsTile";
import { usePreviewContext } from "@/features/LiveFormEditor/LivePreview/context/PreviewContext";
import { trpc } from "@/helpers/trpc";

export type DonationFormV2Step1Props = {
  isEmbed?: boolean;
};

export const DonationFormV2Step1: FC<DonationFormV2Step1Props> = ({ isEmbed = false }) => {
  const { formObject, hasBanner, formCategory, description } = useFormV2Context(FormType.DonationForm);
  const { isExpanded, bannerHeight } = useContext(NotificationBannerHeightContext);
  const { isPreview } = usePreviewContext();
  const { canSubmitFirstStep } = useDonationFirstStepSubmit();
  const utm = useUtmForm();

  const [donorsQuery, setDonorsQuery] = useQueryState(
    "donors",
    parseAsStringLiteral(["view-all"] as const).withOptions({ history: "push" })
  );

  const isDonorsModalOpen = donorsQuery === "view-all";

  const isDonationFormWithTarget = donationFormHasTarget(formCategory);

  // Fetch recent donors if relevant
  const { data: getLatestDonationsResponse } = trpc.getLatestDonations.useQuery(
    {
      formId: formObject.id,
      limit: 6,
      cursor: 0,
    },
    {
      enabled: isDonationFormWithTarget,
      initialData: { items: [] },
    }
  );

  const latestDonations =
    getLatestDonationsResponse?.items?.map((donation) => ({
      ...donation,
      donatedOn: new Date(donation.donatedOn),
      donationAmount: donation.amount,
      donationCurrency: donation.currency,
    })) ?? [];

  const shouldDisplayLatestDonations = isDonationFormWithTarget && latestDonations.length > 0;
  const isOneColumnLayout = !hasBanner && !description;

  const renderDonorsTile = () => (
    <DonorsTile
      onViewAllClick={() => setDonorsQuery("view-all")}
      showViewAllButton={!!getLatestDonationsResponse?.nextCursor}
    >
      {latestDonations.map((donation, index) => (
        <Donor {...donation} key={index} />
      ))}
    </DonorsTile>
  );

  return (
    <HeartsAnimationProvider>
      <DonationFormV2Step1Layout
        isOneColumn={isOneColumnLayout}
        isEmbed={isEmbed}
        contextDrawerHeight={isExpanded ? bannerHeight : 0}
        slots={{
          banner: hasBanner ? <FormV2Banner /> : undefined,
          organizationLogo: <FormV2LogoChip />,
          summary: <FormV2Summary forceInlined={isOneColumnLayout || isEmbed} />,
          donors: shouldDisplayLatestDonations ? renderDonorsTile() : undefined,
          donationAmounts: <Amounts />,
          versionControlAlert: <VersionControlAlertV2 />,
          checkoutFooter: <DonationFormV2CheckoutFooter />,
          divider: <DonationFormDivider />,
        }}
        showCheckoutFooter={canSubmitFirstStep && !isPreview}
        utm={utm}
      />
      {shouldDisplayLatestDonations && (
        <DonorsInformationDialog
          donationFormId={formObject.id}
          open={isDonorsModalOpen}
          onClose={() => setDonorsQuery(null)}
        />
      )}
    </HeartsAnimationProvider>
  );
};
