import { FC } from "react";

import { Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export const DonorSkeleton: FC = () => {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Stack flex={1}>
        <Skeleton variant="text" height={20} sx={{ width: { xs: "80%", sm: 180 } }} />
        <Skeleton variant="text" height={16} sx={{ width: { xs: "50%", sm: 120 } }} />
      </Stack>

      <Stack direction="row" alignItems="center" gap={1}>
        <Skeleton variant="text" width={50} height={28} />
        <Skeleton variant="circular" width={20} height={20} />
      </Stack>
    </Stack>
  );
};
