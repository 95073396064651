import { formatDateTimeToLocaleString, formatDateToMediumDateTime, IsoLocale } from "@simplyk/common";
import { DateTime } from "luxon";

import { Locales } from "../gql/gql-types";

export const formatDateOrIdem = (dateString: string, locale: Locales): string => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return dateString;
  }

  return formatDateToMediumDateTime(date, locale);
};

export interface TransactionDateFilter {
  month: number;
  year: number;
}

export const getFormattedMonthAndYear = ({
  locale,
  dateObject,
}: {
  dateObject: TransactionDateFilter;
  locale: Locales;
}) => {
  const formattedMonth = formatDateTimeToLocaleString(new Date(dateObject.year, dateObject.month, 1), locale, {
    month: "long",
  });
  return `${formattedMonth} ${dateObject.year}`;
};

export const formatRelativeDate = (
  date: Date,
  { locale, justNowLabel }: { locale: IsoLocale; justNowLabel: string }
) => {
  if (Math.abs(DateTime.fromJSDate(date).diffNow().as("seconds")) < 60) {
    // When less than a minute, show "just now"
    return justNowLabel;
  }

  const relativeDate = DateTime.fromJSDate(date).toRelative({ locale });
  return relativeDate ?? "";
};
