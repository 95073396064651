import * as React from "react";

import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import { styled, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { StripeRecurringInterval, getDollarValue, formatCurrencyAmount, getCurrencyFromCountry } from "@simplyk/common";
import classnames from "classnames";

import { FrontendDonationFormContext } from "../../contexts/FrontendDonationFormContext";
import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { useTranslate } from "../../hooks/useTranslate";
import Image from "../Image/Image";
import { DisplayWithInfoFormPreview } from "../InfoForm/InfoForm";

import { AmountsProps } from "./type";
import { useAmounts } from "./useAmounts";

import { AmountInput } from "@/design-system/AmountInput";
import { Button } from "@/design-system/Button";

const PREFIX = "DONATION_AMOUNT_MAX";

const classes = {
  noInteraction: `${PREFIX}-noInteraction`,
  loader: `${PREFIX}-loader`,
  fullHeight: `${PREFIX}-fullHeight`,
  embedForm: `${PREFIX}-embedForm`,
  input: `${PREFIX}-input`,
  banner: `${PREFIX}-banner`,
  labelReminder: `${PREFIX}-labelReminder`,
  subtitle: `${PREFIX}-subtitle`,
  tipsParagraph: `${PREFIX}-tipsParagraph`,
  bigAmountAlert: `${PREFIX}-bigAmountAlert`,
  responsiveDivider: `${PREFIX}-responsiveDivider`,
  amountButton: `${PREFIX}-amountButton`,
  knowMoreButton: `${PREFIX}-knowMoreButton`,
  knowMoreContainer: `${PREFIX}-knowMoreContainer`,
  zeffyLogo: `${PREFIX}-zeffyLogo`,
  starEffect: `${PREFIX}-starEffect`,
  lockIcon: `${PREFIX}-lockIcon`,
  underlined: `${PREFIX}-underlined`,
  securedPayment: `${PREFIX}-securedPayment`,
  heart: `${PREFIX}-heart`,
  contrast: `${PREFIX}-contrast`,
  inputAmount: `${PREFIX}-inputAmount`,
  inputCurrency: `${PREFIX}-inputCurrency`,
  select: `${PREFIX}-select`,
  giveButton: `${PREFIX}-giveButton`,
  encourageMonthlyButton: `${PREFIX}-encourageMonthlyButton`,
  heartRecurrenceLogo: `${PREFIX}-heartRecurrenceLogo`,
  heartRecurrenceLogoSrc: `${PREFIX}-heartRecurrenceLogoSrc`,
  recurrenceListContainer: `${PREFIX}-recurrenceListContainer`,
  marginBottomUpSm: `${PREFIX}-marginBottomUpSm`,
};

const StyledGrid = React.memo(
  styled(Grid)(({ theme }: { theme: Theme }) => ({
    [`&& .${classes.noInteraction}`]: {
      pointerEvents: "none",
      opacity: 0.4,
    },

    [`&& .${classes.loader}`]: {
      marginLeft: theme.spacing(2),
    },

    [`&& .${classes.fullHeight}`]: {
      height: "100%",
    },

    [`&& .${classes.embedForm}`]: {
      padding: theme.spacing(1, 1.5),
      justifyContent: "flex-end",
    },

    [`&& .${classes.input}`]: {
      background: grey[100],
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderColor: theme.palette.neutral[90],
    },

    [`&& .${classes.banner}`]: {
      width: "100%",
      objectFit: "contain",
    },

    [`&& .${classes.labelReminder}`]: {
      marginBottom: theme.spacing(2),
    },

    [`&& .${classes.subtitle}`]: {
      fontWeight: 600,
      color: theme.palette.primary.main,
    },

    [`&& .${classes.tipsParagraph}`]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },

    [`&& .${classes.bigAmountAlert}`]: {
      marginTop: theme.spacing(2),
    },

    [`&& .${classes.responsiveDivider}`]: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.down("lg")]: {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(2),
      },
    },

    [`&& .${classes.amountButton}`]: { fontWeight: 700 },
    [`&& .${classes.knowMoreButton}`]: {
      color: theme.palette.primary.contrastText,
      marginLeft: theme.spacing(2),
      minWidth: 100,
    },
    [`&& .${classes.knowMoreContainer}`]: { marginTop: theme.spacing(1) },
    [`&& .${classes.zeffyLogo}`]: { marginBottom: theme.spacing(2) },
    [`&& .${classes.starEffect}`]: { marginRight: theme.spacing(1), display: "inline-block" },
    [`&& .${classes.lockIcon}`]: { marginRight: theme.spacing(1) },

    [`&& .${classes.underlined}`]: {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },

    [`&& .${classes.securedPayment}`]: { marginRight: 5 },
    [`&& .${classes.heart}`]: { paddingBottom: 2, maxHeight: 24 },
    [`&& .${classes.contrast}`]: { color: theme.palette.primary.contrastText },
    [`&& .${classes.inputAmount}`]: theme.typography.h4,
    [`&& .${classes.inputCurrency}`]: theme.typography.h5,

    [`&& .${classes.select}`]: {
      borderColor: `${theme.palette.neutral[90]} !important`,
    },

    [`&& .${classes.giveButton}`]: {
      marginTop: theme.spacing(2),
      paddingBottom: "0px !important",
    },

    [`&& .${classes.encourageMonthlyButton}`]: {
      position: "relative",
    },

    [`&& .${classes.heartRecurrenceLogo}`]: {
      position: "absolute",
      right: -12,
      top: -12,
      borderRadius: "50%",
    },

    [`&& .${classes.heartRecurrenceLogoSrc}`]: {
      borderRadius: "50%",
    },

    [`&& .${classes.recurrenceListContainer}`]: {
      flexWrap: "nowrap",
    },

    [`&& .${classes.marginBottomUpSm}`]: {
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(6),
      },
    },
  }))
);

export const DONATION_AMOUNT_MAX = 100000000;

export const Amounts = (props: AmountsProps) => {
  const { donationForm } = React.useContext(FrontendDonationFormContext);
  const { organization, displayedFormAmount, stripeRecurrenceInterval, isPreview } =
    React.useContext(FrontendFormContext);
  const { control, register, amounts, errors } = props;

  const { locale, isoLocale } = useLocaleContext();
  const currency = getCurrencyFromCountry(organization?.country);

  const { t } = useTranslate();
  const {
    onClickOneTime,
    onClickMonthly,
    onClickYearly,
    onClickMonthlyAndSetMonthlyValue,
    hasOneTimeAmounts,
    hasMonthlyAmounts,
    hasYearlyAmounts,
    amountsToShow,
    onAmountClick,
    amountInputRef,
    selectedAmount,
    selectedAmountId,
    hideAmounts,
    onAmountChange,
  } = useAmounts(props);

  const encourageMonthlyValue = displayedFormAmount ? Math.round(getDollarValue(displayedFormAmount / 5)) : 0;
  const amountObject = amounts.find((amount) => amount.id === selectedAmountId);

  const rules = React.useMemo(
    () => [
      {
        required: {
          value: true,
          message: t("donationForm", "required"),
        },
      },
      {
        min: {
          value: 100,
          message: t("donationForm", "amountLessThan"),
        },
      },
      {
        max: {
          value: DONATION_AMOUNT_MAX,
          message: t("donationForm", "amountHigherThan", {
            amount: formatCurrencyAmount(getDollarValue(DONATION_AMOUNT_MAX), isoLocale, currency),
          }),
        },
      },
    ],
    [currency, isoLocale, t]
  );

  const hasOnlyOneTimeAmounts = hasOneTimeAmounts && !hasMonthlyAmounts && !hasYearlyAmounts;
  const hasOnlyMonthlyAmounts = hasMonthlyAmounts && !hasOneTimeAmounts && !hasYearlyAmounts;
  const hasOnlyYearlyAmounts = hasYearlyAmounts && !hasOneTimeAmounts && !hasMonthlyAmounts;

  return (
    <DisplayWithInfoFormPreview content={t("donationForm", "preview.customizeAmount")}>
      <StyledGrid container>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <Typography variant="h6">{t("donationForm", "chooseAmount")} </Typography>
        </Grid>
        {(hasYearlyAmounts || hasMonthlyAmounts || hasYearlyAmounts) && (
          <Grid item xs={12} sx={{ position: "relative", marginBottom: 1 }}>
            <Grid container spacing={1} className={classes.recurrenceListContainer}>
              {hasOneTimeAmounts && !hasOnlyOneTimeAmounts && (
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={onClickOneTime}
                    data-test="amount-one-time"
                    variant={!stripeRecurrenceInterval ? "filled" : "outlined"}
                    vibe="brand"
                    size="large"
                    fullWidth
                    className={classes.fullHeight}
                  >
                    {t("donationForm", "oneTimeDonation")}
                  </Button>
                </Grid>
              )}

              {hasMonthlyAmounts ? (
                !isPreview &&
                donationForm.encourageMonthlyDonations &&
                stripeRecurrenceInterval !== StripeRecurringInterval.month &&
                stripeRecurrenceInterval !== StripeRecurringInterval.year &&
                encourageMonthlyValue > 0 ? (
                  <Grid item xs={12} sm={hasOnlyMonthlyAmounts ? 12 : 6}>
                    <Button
                      onClick={onClickMonthlyAndSetMonthlyValue(encourageMonthlyValue)}
                      variant="outlined"
                      vibe="brand"
                      size="large"
                      fullWidth
                      className={classnames(classes.fullHeight, classes.encourageMonthlyButton)}
                      data-test="amounts-encourage-monthly-donations"
                    >
                      {t("donationForm", "encourageRecurrence", {
                        amount: formatCurrencyAmount(encourageMonthlyValue, isoLocale, currency, {
                          style: "currency",
                          currency,
                          currencyDisplay: "symbol",
                          minimumFractionDigits: 0,
                        }),
                      })}
                      <div className={classes.heartRecurrenceLogo}>
                        <Image
                          alt="Heartbeat gif"
                          width={24}
                          height={24}
                          src="/images/Heartbeat.gif"
                          className={classes.heartRecurrenceLogoSrc}
                        />
                      </div>
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={hasOnlyMonthlyAmounts ? 12 : 6}>
                    <Button
                      onClick={onClickMonthly}
                      variant={stripeRecurrenceInterval === StripeRecurringInterval.month ? "filled" : "outlined"}
                      vibe="brand"
                      size="large"
                      fullWidth
                      data-test="amount-monthly"
                      className={classes.fullHeight}
                    >
                      {t("donationForm", "monthlyDonation")}
                    </Button>
                  </Grid>
                )
              ) : (
                <></>
              )}
              {hasYearlyAmounts && (
                <Grid item xs={12} sm={hasOnlyYearlyAmounts ? 12 : 6}>
                  <Button
                    onClick={onClickYearly}
                    variant={stripeRecurrenceInterval === StripeRecurringInterval.year ? "filled" : "outlined"}
                    vibe="brand"
                    size="large"
                    fullWidth
                    data-test="amount-yearly"
                    className={classes.fullHeight}
                  >
                    {t("donationForm", "yearlyDonation")}
                  </Button>
                </Grid>
              )}
              <input type="hidden" {...register("selectedAmountId")} />
            </Grid>
          </Grid>
        )}
        {!hideAmounts && (
          <Grid container spacing={1} sx={{ marginBottom: 1 }}>
            {amountsToShow.map(({ amount, id }, index) => (
              <Grid item key={index} xs={6} sm={3}>
                <Button
                  onClick={onAmountClick(amount, id)}
                  data-test="amount-button"
                  variant={selectedAmount === amount ? "filled" : "outlined"}
                  className={classes.amountButton}
                  vibe="brand"
                  size="large"
                  fullWidth
                >
                  {formatCurrencyAmount(getDollarValue(amount), isoLocale, currency)}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <AmountInput
            debounced={250}
            ref={amountInputRef}
            name="amount"
            control={control}
            data-test="donation-form-amount-input"
            locale={locale}
            currency={currency}
            errorMessage={errors.amount?.message}
            rules={rules}
            disableAutocomplete
            onChange={onAmountChange}
            inputProps={{ className: classes.inputAmount }}
            classes={{ currency: classes.inputCurrency }}
          />
        </Grid>

        {amountObject?.message && (
          <Grid item container xs={12} className={classes.labelReminder}>
            <Grid item>
              <Typography variant="subtitle2" className={classes.underlined} data-test="amount-subtitle">
                {amountObject.message}
              </Typography>
            </Grid>
          </Grid>
        )}
      </StyledGrid>
    </DisplayWithInfoFormPreview>
  );
};
