import { FC } from "react";

import { Stack } from "@mui/material";

import { BaseFormV2Step1Layout, BaseFormV2Step1LayoutProps } from "./BaseFormV2Step1Layout";

import { useMediaQuery } from "@/hooks/useMediaQuery";

export type DonationFormV2Step1LayoutProps = Omit<BaseFormV2Step1LayoutProps, "slots"> & {
  slots: {
    banner?: React.ReactNode;
    organizationLogo: React.ReactNode;
    summary: React.ReactNode;
    donors?: React.ReactNode;
    donationAmounts: React.ReactNode;
    versionControlAlert: React.ReactNode;
    checkoutFooter: React.ReactNode;
    divider?: React.ReactNode;
  };
};

export const DonationFormV2Step1Layout: FC<DonationFormV2Step1LayoutProps> = ({
  slots,
  isOneColumn,
  isEmbed,
  ...rest
}) => {
  const { isSmallScreen } = useMediaQuery();

  return (
    <BaseFormV2Step1Layout
      {...rest}
      isOneColumn={isOneColumn}
      isEmbed={isEmbed}
      slots={{
        banner: slots.banner,
        info: (
          <Stack alignItems="flex-start" width="100%" gap={2}>
            {!isSmallScreen && slots.organizationLogo}
            {slots.summary}
            {!isSmallScreen && !isOneColumn && !isEmbed && slots.donors}
          </Stack>
        ),
        order: (
          <Stack width="100%" gap={2}>
            {slots.donationAmounts}
            {slots.versionControlAlert}
            {(isSmallScreen || isOneColumn || isEmbed) && slots.donors}
          </Stack>
        ),
        checkoutFooter: slots.checkoutFooter,
        divider: slots.divider,
      }}
    />
  );
};
