import { FormType } from "@simplyk/common";
import { useWatch } from "react-hook-form";

import { scrollToTop } from "../../../../helpers/utils";
import { useFormV2Context } from "../../context/FormV2Context";

import { AmplitudeEvents } from "@/constants/amplitude";
import { useAmplitude } from "@/hooks/amplitude/useAmplitude";

export const useDonationFirstStepSubmit = () => {
  const { logAmplitudeEvent } = useAmplitude();
  const { form, setCurrentStep, formObject: donationForm, setValidationMode } = useFormV2Context(FormType.DonationForm);

  const { trigger, control } = form;

  const logDonorFormStepSubmitted = () => {
    logAmplitudeEvent(AmplitudeEvents.DonorFormStepSubmitted, {
      formId: donationForm.id,
      formType: donationForm.category,
      organizationCountry: donationForm.organization?.country,
    });
  };

  const donationAmount = useWatch({
    control,
    name: "amount",
  });
  const recurrence = useWatch({
    control,
    name: "recurrenceInterval",
  });

  const canSubmitFirstStep: boolean = donationAmount > 0;

  const onFirstStepSubmit = async () => {
    if (!canSubmitFirstStep) {
      return;
    }

    const isValid = await trigger(["amount"], {
      shouldFocus: true,
    });

    if (isValid) {
      scrollToTop("auto");
      setCurrentStep("payment");
      logDonorFormStepSubmitted();
      setValidationMode("onBlur");
    } else {
      setValidationMode("onChange");
    }
  };

  return { onFirstStepSubmit, canSubmitFirstStep, recurrence };
};
