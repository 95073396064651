import { FC } from "react";

import { useTranslate } from "../../../../hooks/useTranslate";
import { useDonationFirstStepSubmit } from "../DonationForm/useDonationFirststepSubmit";

import { CheckoutButton } from "./CheckoutButton";

export const DonationFormV2CheckoutFooter: FC = () => {
  const { t } = useTranslate();
  const { onFirstStepSubmit, canSubmitFirstStep } = useDonationFirstStepSubmit();

  return (
    <CheckoutButton onSubmit={onFirstStepSubmit} canSubmit={canSubmitFirstStep} label={t("donationForm", "continue")} />
  );
};
