import { useContext, useRef, useState } from "react";

import { hslToRgb, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import {
  StripeRecurringInterval,
  getDollarValue,
  formatCurrencyAmount,
  getCurrencyFromCountry,
  formatCurrencyAmountWithoutCents,
} from "@simplyk/common";
import { useWatch } from "react-hook-form";

import { DONATION_AMOUNT_MAX } from "../../../../components/Amounts/Amounts";
import { AmountInput, AmountInputHandle } from "../../../../components/design-system/AmountInput";
import { Button } from "../../../../components/design-system/Button";
import { Typography } from "../../../../components/design-system/Typography";
import { getStripeSubscriptionRecurrence } from "../../../../components/PaymentProcessor/helper";
import { FrontendFormContext } from "../../../../contexts/FrontendFormContext";
import { useLocaleContext } from "../../../../contexts/LocaleContext";
import { FormType } from "../../../../gql/gql-types";
import { useFormV2Context } from "../../context/FormV2Context";
import { HeartsAnimationContext } from "../../context/heartsAnimationContext";
import { useShapes } from "../../sharedComponents/BackgroundShapes/ShapesContext";
import { ButtonWithHeartAnimation } from "../../sharedComponents/ButtonWithHeartAnimation/ButtonWithHeartAnimation";

import { usePreviewContext } from "@/features/LiveFormEditor/LivePreview/context/PreviewContext";
import { GHOST_PREVIEW_OPACITY } from "@/features/LiveFormEditor/LivePreview/ghostPreview.constant";
import { useIsGhostPreview } from "@/features/LiveFormEditor/LivePreview/useIsGhostPreview";
import { useTranslate } from "@/hooks/useTranslate";
import { Heart } from "@/icons/other";

const transition: string = "background-color ease-in 0.3s";

const amountInputSx = (isSelected: boolean) => {
  return {
    transition,
    backgroundColor: (theme: Theme) =>
      isSelected
        ? `${theme.palette.surface.brand.intense} !important`
        : `${theme.palette.surface.form.supershy} !important`,
    "&.ZeffyButton-brand-outlined:active": {
      outline: "none",
    },
    "&.ZeffyButton-brand-filled:active": {
      outline: "none",
    },
    "&.ZeffyButton-brand-outlined": {
      border: (theme: Theme) => `1px solid ${theme.palette.border.form.quiet}`,
    },
    ...(!isSelected && {
      "&:hover": {
        transition: "background-color ease-in 0.3s",
        backgroundColor: (theme: Theme) => `${theme.palette.surface.form.quiet} !important`,
      },
    }),
  };
};

const frequencyButtonSx = (isSelected: boolean) => {
  return {
    flex: 1,
    minWidth: 0,
    height: { xs: 26 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingY: 2,
    paddingX: 4,
    transition,
    backgroundColor: (theme: Theme) =>
      isSelected
        ? `${theme.palette.surface.form.intense} !important`
        : `${theme.palette.surface.form.quiet} !important`,
    "&.ZeffyButton-brand-tonal:active": {
      outline: "none",
    },
    ...(!isSelected && {
      "&:hover": {
        transition,
        backgroundColor: (theme: Theme) => `${theme.palette.surface.form.moderate} !important`,
      },
    }),
  };
};

export const Amounts = () => {
  const theme = useTheme();
  const { t } = useTranslate();
  const { isPreview } = usePreviewContext();
  const { locale, isoLocale } = useLocaleContext();
  const { setDisplayedFormAmount, setStripeRecurrenceInterval } = useContext(FrontendFormContext);
  const { rotate, reverseRotate } = useShapes();
  const { triggerHeartsAnimation } = useContext(HeartsAnimationContext);
  const [shouldDisplayHeartsAnimation, setShouldDisplayHeartsAnimation] = useState(false);
  const displayHeartsOnHover = () => {
    setShouldDisplayHeartsAnimation(true);
    setTimeout(() => setShouldDisplayHeartsAnimation(false), 2000);
  };
  const isGhostPreview = useIsGhostPreview("Amounts");

  const {
    formObject: donationForm,
    form,
    suggestedAmounts,
    hasSuggestedAmountsPerType,
    amountRecurrenceInterval,
  } = useFormV2Context(FormType.DonationForm);
  const currency = getCurrencyFromCountry(donationForm?.organization?.country);

  const selectedAmount = useWatch({ control: form.control, name: "amount" });
  const selectedAmountId = useWatch({ control: form.control, name: "selectedAmountId" });
  const stripeRecurrenceInterval = useWatch({ control: form.control, name: "recurrenceInterval" });
  const amountInputRef = useRef<AmountInputHandle>(null);

  const hideSuggestedAmounts = suggestedAmounts.length === 0;
  const suggestedAmountsToShow = suggestedAmounts.filter(
    (amount) => getStripeSubscriptionRecurrence(amount.recurrenceInterval) === stripeRecurrenceInterval
  );
  const selectedSuggestedAmountObject = suggestedAmounts.find((amount) => amount.id === selectedAmountId);

  const rules = [
    {
      required: {
        value: true,
        message: t("donationForm", "required"),
      },
    },
    {
      min: {
        value: 100,
        message: t("donationForm", "amountLessThan"),
      },
    },
    {
      max: {
        value: DONATION_AMOUNT_MAX,
        message: t("donationForm", "amountHigherThan", {
          amount: formatCurrencyAmount(getDollarValue(DONATION_AMOUNT_MAX), isoLocale, currency),
        }),
      },
    },
  ];

  const handleRecurringClick = (recurrenceInterval: StripeRecurringInterval | null) => {
    form.setValue("recurrenceInterval", recurrenceInterval);
    const newAmountAndId = suggestedAmounts
      .filter((amount) => getStripeSubscriptionRecurrence(amount.recurrenceInterval) === recurrenceInterval)
      .find((amount) => amount.amount === selectedAmount);
    form.setValue("selectedAmountId", newAmountAndId?.id);
    setStripeRecurrenceInterval(recurrenceInterval);
  };

  const onClickOneTime = () => handleRecurringClick(null);
  const onClickMonthly = () => handleRecurringClick(StripeRecurringInterval.month);
  const onClickYearly = () => handleRecurringClick(StripeRecurringInterval.year);

  const createSuggestedAmountClickHandler = (amount: number, id: string) => {
    return () => {
      setDisplayedFormAmount(amount);
      triggerHeartsAnimation();
      if (amount > selectedAmount) {
        rotate();
      } else if (amount < selectedAmount) {
        reverseRotate();
      }
      form.setValue("amount", amount, { shouldValidate: true });
      form.setValue("selectedAmountId", id);
      amountInputRef?.current?.editValue(amount);
    };
  };

  const onManualAmountInputChanged = (value: number | null) => {
    if (!value || value < selectedAmount) {
      reverseRotate();
    } else if (value > selectedAmount) {
      triggerHeartsAnimation();
      rotate();
    }

    form.setValue("amount", value ?? 0, { shouldValidate: true });
    setDisplayedFormAmount(value ?? 0);

    const matchingSuggestedAmount = suggestedAmountsToShow.find((amount) => amount.amount === value);
    form.setValue("selectedAmountId", matchingSuggestedAmount?.id);
  };

  const hasSuggestedAmounts =
    hasSuggestedAmountsPerType.oneTime || hasSuggestedAmountsPerType.monthly || hasSuggestedAmountsPerType.yearly;
  const getAmountWording = () => {
    if (amountRecurrenceInterval.onlyMonthly) {
      return t("donationForm", "form.chooseMonthlyAmount");
    }

    if (amountRecurrenceInterval.onlyYearly) {
      return t("donationForm", "form.chooseYearlyAmount");
    }

    if (hasSuggestedAmounts) {
      return t("donationForm", "form.chooseAmount");
    }

    return t("donationForm", "form.donationAmount");
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ...(isGhostPreview && {
          opacity: GHOST_PREVIEW_OPACITY,
        }),
      }}
    >
      <Box marginBottom={!hasSuggestedAmounts ? 1.5 : 0}>
        <Typography variant="subtitle1">{getAmountWording()}:</Typography>
      </Box>

      {/* Recurrence Buttons */}
      {!hideSuggestedAmounts && !amountRecurrenceInterval.onlyOneType && (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            background: (theme) => theme.palette.surface.form.quiet,
            borderRadius: 1,
            p: 0.5,
            width: "100%",
            marginTop: 1.5,
          }}
        >
          {hasSuggestedAmountsPerType.oneTime && (
            <Button
              onClick={onClickOneTime}
              data-test="amount-one-time"
              size="large"
              variant="tonal"
              sx={frequencyButtonSx(!stripeRecurrenceInterval)}
            >
              {t("donationForm", "oneTime").charAt(0).toUpperCase() + t("donationForm", "oneTime").slice(1)}
            </Button>
          )}

          {hasSuggestedAmountsPerType.monthly && (
            <ButtonWithHeartAnimation
              onClick={onClickMonthly}
              onMouseEnter={displayHeartsOnHover}
              label={
                <Typography variant="subtitle1" sx={{ display: "inline-flex", alignItems: "center", fontWeight: 500 }}>
                  <Heart style={{ width: 12, height: "auto", marginRight: 4, fill: "red" }} />
                  {t("donationForm", "monthly").charAt(0).toUpperCase() + t("donationForm", "monthly").slice(1)}
                </Typography>
              }
              variant="tonal"
              showAnimation={shouldDisplayHeartsAnimation}
              shouldLoop={false}
              data-test="amount-monthly"
              rgbHeartsColor={hslToRgb(theme.palette.surface.form.superintense)}
              sx={frequencyButtonSx(stripeRecurrenceInterval === StripeRecurringInterval.month)}
            />
          )}

          {hasSuggestedAmountsPerType.yearly && (
            <Button
              onClick={onClickYearly}
              variant="tonal"
              size="large"
              data-test="amount-yearly"
              sx={frequencyButtonSx(stripeRecurrenceInterval === StripeRecurringInterval.year)}
            >
              {t("donationForm", "yearly")}
            </Button>
          )}
        </Box>
      )}

      {/* Suggested Amounts */}
      {!hideSuggestedAmounts && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" },
            gap: 1,
            marginBottom: 2,
            marginTop: 2,
          }}
        >
          {suggestedAmountsToShow.map(({ amount, id }) => (
            <Button
              key={id}
              onClick={createSuggestedAmountClickHandler(amount, id)}
              data-test="suggested-amount-button"
              variant={selectedAmount === amount ? "filled" : "outlined"}
              sx={amountInputSx(selectedAmount === amount)}
              vibe="brand"
              size="large"
              fullWidth
            >
              <Typography variant="h6">
                {amount % 100 > 0
                  ? formatCurrencyAmount(getDollarValue(amount), isoLocale, currency)
                  : formatCurrencyAmountWithoutCents(getDollarValue(amount), isoLocale, currency)}
              </Typography>
            </Button>
          ))}
        </Box>
      )}

      {/* Manual Amount Input */}
      <Box>
        <AmountInput
          ref={amountInputRef}
          name="amount"
          control={form.control}
          onChange={onManualAmountInputChanged}
          errorMessage={form.formState.errors.amount?.message}
          placeholder={hasSuggestedAmounts ? t("donationForm", "other") : undefined}
          data-test="donation-form-amount-input"
          locale={locale}
          currency={currency}
          rules={rules}
          disableAutocomplete
          classes={{
            currency: "amountsCurrencyClass",
          }}
          disableInjectingGlobalStyles={isPreview}
          sx={(theme) => ({
            "& .MuiInputBase-input": {
              fontSize: theme.typography.h5.fontSize,
              fontWeight: theme.typography.h5.fontWeight,
              lineHeight: theme.typography.h5.lineHeight,
              paddingY: 1.5,
              "&::placeholder": {
                color: `${theme.palette.text.reverse.quiet} !important`,
              },
            },
            "&& .amountsCurrencyClass": {
              fontSize: theme.typography.h5.fontSize,
              fontWeight: theme.typography.h5.fontWeight,
              lineHeight: theme.typography.h5.lineHeight,
              color: theme.palette.text.form.moderate,
              marginLeft: 1,
            },
            "&.ZeffyInput-focused": {
              boxShadow: (theme: Theme) => `0 0 0 3px ${theme.palette.surface.form.moderate}`,
              border: (theme: Theme) => `1px solid ${theme.palette.surface.brand.intense}`,
            },
            "&.ZeffyInput-form:hover": {
              border: (theme: Theme) => `1px solid ${theme.palette.surface.brand.intense}`,
            },
          })}
          currencyPlacement="start"
          endAdornment={
            stripeRecurrenceInterval && (
              <Typography
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.text.reverse.quiet,
                  marginRight: 1,
                  fontSize: `${theme.typography.h5.fontSize} !important`,
                  fontWeight: `${theme.typography.h5.fontWeight} !important`,
                  lineHeight: `${theme.typography.h5.lineHeight} !important`,
                })}
              >
                {stripeRecurrenceInterval === StripeRecurringInterval.month && `/${t("donationForm", "month")}`}
                {stripeRecurrenceInterval === StripeRecurringInterval.year && `/${t("donationForm", "year")}`}
              </Typography>
            )
          }
        />
      </Box>

      {selectedSuggestedAmountObject?.message && (
        <Typography
          variant="subtitle2"
          sx={(theme) => ({ color: theme.palette.surface.brand.intense })}
          data-test="amount-subtitle"
        >
          {selectedSuggestedAmountObject.message}
        </Typography>
      )}
    </Box>
  );
};
