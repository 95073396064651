import { FC } from "react";

import { Box, Stack, useTheme } from "@mui/material";

export type InformationDialogLayoutProps = {
  slots: {
    title: React.ReactNode;
    closeButton?: React.ReactNode;
    content: React.ReactNode;
    actions?: React.ReactNode;
  };
  noDivider?: boolean;
};

export const InformationDialogLayout: FC<InformationDialogLayoutProps> = ({ slots, noDivider = false }) => {
  const theme = useTheme();

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding={theme.spacing(2, 2, 2, 3)}
        sx={{ borderBlockEnd: noDivider ? "none" : `1px solid ${theme.palette.border.form.quiet}` }}
      >
        <Box flexGrow={1}>{slots.title}</Box>
        {!!slots.closeButton && <Box flexShrink={0}>{slots.closeButton}</Box>}
      </Stack>
      {slots.content}
      {slots.actions && <Box>{slots.actions}</Box>}
    </Stack>
  );
};
