import { FC } from "react";

import { Stack, useTheme } from "@mui/material";
import { getDollarValue, formatNarrowCurrencyAmount, AvailablePaymentCurrency, capitalize } from "@simplyk/common";

import { useLocaleContext } from "@/contexts/LocaleContext";
import { Typography } from "@/design-system/Typography";
import { formatRelativeDate } from "@/helpers/date";
import { useTranslate } from "@/hooks/useTranslate";

export type DonorProps = {
  fullName: string | null;
  donatedOn: Date;
  donationAmount: number;
  donationCurrency: AvailablePaymentCurrency;
};

export const Donor: FC<DonorProps> = ({ fullName, donatedOn, donationAmount, donationCurrency }) => {
  const { t } = useTranslate();
  const { isoLocale } = useLocaleContext();
  const isWholeDollar = donationAmount % 100 === 0;
  const theme = useTheme();

  const formattedDonationAmount = formatNarrowCurrencyAmount(getDollarValue(donationAmount), {
    locale: isoLocale,
    currency: donationCurrency,
    withCents: !isWholeDollar,
  });
  /* Inline the SVG so we can set multiple dynamic colors */
  const heart = (
    <svg
      className="svg-safari-fix"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "relative", top: "-1px" }} // Align with text
    >
      <path
        d="M9.9499 18.2323C9.6249 18.2323 9.2999 18.124 9.0249 17.9073C3.6749 13.6823 2.3749 10.9656 2.18324 10.499C1.83324 9.83229 1.6499 9.08229 1.6499 8.32396C1.6499 5.63229 3.85824 3.26562 6.36657 3.26562C7.93324 3.26562 9.18324 4.25729 9.9999 5.36563C10.8082 4.25729 12.0666 3.26562 13.6332 3.26562C16.1499 3.26562 18.3499 5.63229 18.3499 8.32396C18.3499 9.63229 17.6916 10.7073 17.1999 11.399C16.6249 12.224 14.8666 14.749 10.8749 17.9073C10.6082 18.124 10.2832 18.2323 9.95823 18.2323H9.9499Z"
        fill={theme.palette.surface.form.quiet}
      />
      <path
        d="M9.9499 18.2342C9.6249 18.2342 9.2999 18.1259 9.0249 17.9092C3.6749 13.6842 2.3749 10.9676 2.18324 10.5009C1.83324 9.83424 1.6499 9.08424 1.6499 8.32591C1.6499 5.63424 3.85824 3.26758 6.36657 3.26758C7.93324 3.26758 9.18324 4.25924 9.9999 5.36758C10.8082 4.25924 12.0666 3.26758 13.6332 3.26758C16.1499 3.26758 18.3499 5.63424 18.3499 8.32591C18.3499 9.63424 17.6916 10.7092 17.1999 11.4009C16.6249 12.2259 14.8666 14.7509 10.8749 17.9092C10.6082 18.1259 10.2832 18.2342 9.95823 18.2342H9.9499ZM6.36657 4.90091C4.7499 4.90091 3.2749 6.53424 3.2749 8.33425C3.2749 8.85091 3.3999 9.33424 3.63324 9.78424L3.68324 9.89258C3.68324 9.89258 4.70823 12.4176 9.9499 16.5759C13.6916 13.6092 15.3249 11.2592 15.8666 10.4842C16.4582 9.65091 16.7166 8.99258 16.7166 8.34258C16.7166 6.55091 15.2416 4.90924 13.6249 4.90924C12.4416 4.90924 11.3582 6.04258 10.8582 7.09258C10.6999 7.43424 10.3666 7.64258 9.99157 7.64258C9.61657 7.64258 9.28323 7.43424 9.1249 7.09258C8.6249 6.04258 7.5499 4.90924 6.35824 4.90924L6.36657 4.90091Z"
        fill={theme.palette.surface.brand.intense}
      />
    </svg>
  );

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Stack flexGrow={1} minWidth={0}>
        <Typography
          variant="body2"
          vibe="text-form-moderate"
          sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {fullName ?? t("donationForm", "peerToPeer.donor.anonymous")}
        </Typography>
        <Typography variant="caption" vibe="text-form-quiet">
          {capitalize(formatRelativeDate(donatedOn, { locale: isoLocale, justNowLabel: t("common", "justNow") }))}
        </Typography>
      </Stack>
      <Stack display="inline-flex" direction="row" alignItems="center" gap={1}>
        <Typography variant="h6" sx={{ color: theme.palette.surface.brand.intense }}>
          {formattedDonationAmount}
        </Typography>
        {heart}
      </Stack>
    </Stack>
  );
};
