import React, { FC } from "react";

import { Stack } from "@mui/material";

import { Button } from "@/design-system/Button";
import { Divider } from "@/design-system/Divider";
import { Typography } from "@/design-system/Typography";
import { Tile, TileProps } from "@/features/FormV2/sharedComponents/Tile/Tile";
import { useTranslate } from "@/hooks/useTranslate";

export type DonorsTileProps = Omit<TileProps, "ref"> & {
  children: React.ReactNode;
  onViewAllClick: () => void;
  showViewAllButton?: boolean;
};

export const DonorsTile: FC<DonorsTileProps> = ({ onViewAllClick, children, showViewAllButton = false, ...rest }) => {
  const { t } = useTranslate();

  return (
    <Tile {...rest}>
      <Stack alignItems="flex-start" gap={2}>
        <Typography variant="subtitle1" vibe="text-form-quiet">
          {t("donationForm", "peerToPeer.donorsCard.title")}
        </Typography>
        <Stack width="100%" gap={1} divider={<Divider />}>
          {children}
        </Stack>
        {showViewAllButton && (
          <Button variant="outlined" vibe="brand" size="small" onClick={onViewAllClick}>
            {t("donationForm", "peerToPeer.donorsCard.viewAll")}
          </Button>
        )}
      </Stack>
    </Tile>
  );
};
